import get from 'lodash/get'

export default (v$: any, fieldsNames: string[]) => {
  return (
    get(
      v$,
      fieldsNames.find((fieldName) => get(v$, fieldName).$errors[0]) as string
    )?.$errors[0]?.$message.toString() || ''
  )
}

export const useGetFieldErrorsByIndex = (v$: any, pathName: string, fieldName: string, index: number) => {
  return v$[pathName] && v$[pathName].$errors.length && v$[pathName].$errors[0].$response.$errors[index]
    ? v$[pathName].$errors[0].$response.$errors[index][fieldName][0]?.$message.toString()
    : ''
}
